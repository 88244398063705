import { Inject } from 'inversify-props'
import type { TipoDeClienteServiceAdapter } from '../adapter'

export class DeleteTipoDeClienteUseCase {
	@Inject('TipoDeClienteServiceAdapter')
	private tipoDeClienteService!: TipoDeClienteServiceAdapter

	delete = async (id: string): Promise<void> =>
		await this.tipoDeClienteService.remove(id)

}
