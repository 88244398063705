var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"560"},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true),model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.salvar.apply(null, arguments)}}},[(_vm.plano)?_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.planoAnterior ? 'Editar' : 'Novo')+" Plano ")])],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome","rules":[_vm.obrigatorio]},model:{value:(_vm.plano.nome),callback:function ($$v) {_vm.$set(_vm.plano, "nome", $$v)},expression:"plano.nome"}})],1),_c('v-col',{staticClass:"d-flex align-center pr-0",attrs:{"cols":"3"}},[_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"value":!!_vm.plano.expiraEm,"hide-details":""},on:{"change":function (valor) {
								this$1.plano.expiraEm = valor
									? _vm.criarExpiraEm()
									: null
							}}}),_c('v-text-field',{staticClass:"alinhar-campo-a-direita",staticStyle:{"width":"130px"},attrs:{"value":_vm.plano.expiraEm ? _vm.plano.expiraEm.quantidade : null,"label":"Expira em","type":"number","disabled":!_vm.plano.expiraEm,"min":"0"},on:{"input":function (valor) {
								if (!this$1.plano.expiraEm) { return }
								this$1.plano.expiraEm.quantidade = valor
							}}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"3"}},[_c('v-select',{staticStyle:{"max-width":"130px"},attrs:{"items":_vm.listasDeUnidadesDeTempo,"value":_vm.plano.expiraEm ? _vm.plano.expiraEm.unidade : null,"menu-props":"auto","return-object":false,"disabled":!_vm.plano.expiraEm,"mandatory":""},on:{"input":function (valor) {
								if (!this$1.plano.expiraEm) { return }
								this$1.plano.expiraEm.unidade = valor
							}}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"3","sm":"6"}},[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v("Aplicar nas etiquetas")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",staticStyle:{"cursor":"pointer"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2492012806)},[_vm._v(" Esse parâmetro define se a impressão de preços nas etiquetas exibirão os valores dos planos com flag ativa ")])],1)]},proxy:true}],null,false,660593720),model:{value:(_vm.plano.aplicarNasEtiquetas),callback:function ($$v) {_vm.$set(_vm.plano, "aplicarNasEtiquetas", $$v)},expression:"plano.aplicarNasEtiquetas"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":""}},[_c('v-text-field',{attrs:{"label":"URL de cadastro"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1048144600)},[_vm._v(" Em desenvolvimento ")])]},proxy:true}],null,false,472544772),model:{value:(_vm.plano.urlExterna),callback:function ($$v) {_vm.$set(_vm.plano, "urlExterna", $$v)},expression:"plano.urlExterna"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('BuscaDeProdutoDropdown',{attrs:{"label":"Produto associado ao plano","return-object":"","requerFilho":""},model:{value:(_vm.plano.produtoDoPlano),callback:function ($$v) {_vm.$set(_vm.plano, "produtoDoPlano", $$v)},expression:"plano.produtoDoPlano"}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"v-input__append-outer",staticStyle:{"cursor":"pointer"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,4061247183)},[_vm._v(" Esse produto será incluso durante o cadastro do cliente em uma nova compra ")])],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () { return _vm.mostra = false; }}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary","type":"submit"}},[_vm._v(" Salvar ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }