



















































































import { Component,Ref,Vue } from 'vue-property-decorator'
import DialogoDeEdicaoDePlano from '@/components/planos/DialogoDeEdicaoDePlano.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { FindTipoDeClienteUseCase } from '@/usecases'
import { DeleteTipoDeClienteUseCase } from '@/usecases/cliente/tipodecliente/DeleteTipoDeClienteUseCase'
import { TipoDeCliente, UnidadeDeTempo } from '@/models'
import { listasDeUnidadesDeTempo } from '@/shareds/cliente-shareds'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'


@Component({
	components:{
		DialogoDeEdicaoDePlano,
		Confirmacao,
	},
})

export default class TelaListasDePlanos extends Vue {
	@Ref() dialogo!: DialogoDeEdicaoDePlano

	mostrar = false
	findTipoDeClienteUseCase = new FindTipoDeClienteUseCase()
	deleteTipoDeClienteUseCase = new DeleteTipoDeClienteUseCase()

	planos: TipoDeCliente[] = []
	listasDeUnidadesDeTempo = listasDeUnidadesDeTempo
	carregando = false
	excluindo: string[] = []
	
	async created() {
		try {
			this.carregando = true
			this.planos = (await this.findTipoDeClienteUseCase.find()).content
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	displayUnidadeDeTempo(unidade: UnidadeDeTempo){
		return listasDeUnidadesDeTempo.find(
			item => item.value == unidade,
		)?.text || ''
	}

	atualizarPlano(plano: TipoDeCliente){
		if (!this.planos) return
		const indice = this.planos.findIndex(
			planoExistente => planoExistente.id === plano.id,
		)
		indice === -1
			? this.planos.push(plano)
			: this.planos.splice(indice, 1, plano)
	}

	abrirDialogoDeEdicao(){
		this.dialogo.mostrar()
	}

	async excluirPlano(indice: number) {
		const plano = this.planos[indice]
		if (!plano) return

		try {
			this.excluindo.push(plano.id)
			await this.deleteTipoDeClienteUseCase.delete(plano.id)
			this.planos = this.planos.filter(
				({ id }) => id !== plano.id,
			)
		} catch(error: any) {
			AlertModule.setError(error.response.data[0])
		} finally {
			this.excluindo = this.excluindo.filter(id => id !== plano.id)
		}
	}
}

